import React          from "react";


import Form           from "@cBehaviour/form";
import T              from "@cBehaviour/i18n";
import Slot           from "@cComponents/slot";
import Input          from "@cComponents/old/input";
import NInput         from "@cComponents/input";
import Display        from "@cComponents/displayIf";
import Button         from "@cComponents/button";
import ScrollBar      from "@cComponents/scrollBar";
import FormError      from "@cComponents/formError";
import Social         from "@entities/social";
import Application    from "@uBehaviour/application";


import "./form.css";

class FormStep extends React.Component{

  static Title    = Slot();
  static Comment  = Slot();
  static Input    = Slot();
  
  render(){
    const title     = FormStep.Title.get(this);
    const comment   = FormStep.Comment.get(this);
    const input     = FormStep.Input.get(this);
    const required  = input.props.required;
    const disabled  = this.props.disabled;

    return (
      <div className={`bs-form-step ${ disabled ? "bs-form-step-disabled" : ""}`} >
        <div><div className="bs-form-step-number">{ this.props.number }</div></div>
        <div className="bs-form-step-content">
          <div className="bs-form-step-title">
            { title }
            <Display.If condition={ required }>
              <Display.Then><span className="bs-form-step-title-required">*</span></Display.Then>
              <Display.Else><span className="bs-form-step-title-optional">(<T>optional</T>)</span></Display.Else>
            </Display.If>
          </div>
          <Display.If condition={ comment }>
            <div className="bs-form-step-comment">{ comment }</div>
          </Display.If>
          <div className="bs-form-step-input">{ input }</div>
        </div>
      </div>
    )
  }
}

class IssueForm extends React.Component{
  constructor(props){
    super(props);
    this._form               = React.createRef();
    this._preload            = this._preload.bind(this);
    this._presubmit          = this._presubmit.bind(this);
    this._onFormChange       = this._onFormChange.bind(this);
    this._onSubmitted        = this._onSubmitted.bind(this);
    this._categories         = null;
    this.state               = { disabled: false };
    this._initialCategory    = "";
  }
  componentWillUnmount() {
    if (this._closeErrorMessage) {
      this._closeErrorMessage();
    }
  }
  _preload(val){
    let datas = {
      position: this.props.configuration.get("default_map_position"),
      address: null,
      files: [],
      description: "",
      category: null
    }
    this.props.geolocation.getUserLocation().then(location => ({
      position: location.position,
      address: location.address,
      files: [],
      description: "",
      category: null
    }))
    .then(datas => this._loadCategories(datas).then(() => datas))
    .then(datas => {
      this._form.current.update(datas, { fullname: "address"});
    });
    return datas;
  }
  _loadCategories(datas){
    if(!(datas && datas.position)){
      this._categories = null;
      return Promise.resolve();
    }
    return this.props.api.service("categories", "getPublicSpaceByPosition").execute(datas.position)
      .then(categories => {
        this._categories = categories;
      }, err => {
        if (err.code === 404) {
          this._categories = null;
        }
      }).then(() => {
        if(!this._categories?.length){
          this.setState({ disabled: true });
          this._closeErrorMessage = this.props.message.send("error", this.props.i18n.translate("issue_form_citizen_issue_forbidden"), 10000, "center");
        }
        else {
          this.setState({ disabled: false });
        }
      });
  }
  _presubmit(value){
    value.location = {
      address: value.address,
      position: value.position
    };
    delete value.address;
    delete value.position;
    return value;
  }
  _reloadCategory(value, origin){
    let updated = false;
    let p = Promise.resolve(value);
    const reloadCategories = (value => this._loadCategories(value).then(() => {
      if(this._lastCategory && !this._categories?.find(category => category._id === this._lastCategory._id)){
        const category = this._categories?.find(category => category.key === this._lastCategory.key);
        value.category = category ? category._id : null;
        updated = true;
      }
    }).then(() => value));
    if(origin === "address" && value.address){
      p = this.props.geolocation.getPositionFromAddress(value.address).then(position => {
        value.position = position;
        updated = true;
        return value;
      }).then(reloadCategories);
    } else if(origin === "position" && value.position){
      p = this.props.geolocation.getAddressFromPosition(value.position).then(address => {
        value.address = address;
        updated = true;
        return value;
      }).then(reloadCategories);
    }
    p = p.then(value => {
      if(value.category) {
        this._lastCategory = this._categories.find(category => category._id === value.category);
        updated = true;
      }
      return value;
    });
    return p.then(value => {
      if(updated){
        this._form.current.update(value, origin);
      }
    });
  }

  _onFormChange(value, origin){
    if ((origin === "address" || origin === "position") && this._closeErrorMessage) {
      this._closeErrorMessage();
    }
    this._reloadCategory(value, origin);

    if (origin === "category" && value.category) {
      this._initialCategory = value.category; 
    }
    return value;
  }

  _sendOnCreatedMessage(issue, message  ) {
    const i18nService = this.props.i18n;
      this.props.api.service("tenants", "getOne").execute(issue.tenant).then(tenant => {
      if(message === "issue_creation_successfull_on_client_tenant_redirect" ){
        this.props.repository
        .get('Category')
        .repository.get([{ _id: issue.category }])
        .then(([newCategory]) => {
          this.props.message.send("success", i18nService.translate(message, {
            tenantName: i18nService.translateObject(tenant.name),
            bsId: issue.bsId,
            categoryName: i18nService.translateObject( newCategory.label)
          }))
        })  
      }
      else{
      this.props.message.send("success", i18nService.translate(message, {
        tenantName: i18nService.translateObject(tenant.name),
        bsId: issue.bsId
      }))
        
      }
     
      
    })
  }
  _onSubmitted({ issue, message }){
    let url = "/issues/" + issue._id;
    
    if(issue.category !== this._initialCategory && message === "issue_creation_successfull_on_client_tenant" ){
      if(issue.public === true){
        message = "issue_creation_successfull_on_client_tenant_redirect"
      } else {
        message = "issue_creation_successfull_on_client_tenant_redirect_private"
      }
    }
    this._sendOnCreatedMessage(issue, message);
    this.props.geolocation.updateLastUse(issue.location.position);
    try{
      this.props.navigator.go(url);
    }catch(e){};  
  }

  _extractValueAfterSubmit = (value) => value.issue;

  render(){
    return (
      <ScrollBar>
        <div className="bs-wrapper bs-form-issue">
          <Form.Model model={ "Issue" } preload={ this._preload } presubmit={ this._presubmit } onChange={ this._onFormChange } extractValueAfterSubmit={this._extractValueAfterSubmit} onSubmitted={ this._onSubmitted } ref={ this._form }>
          {(issue, error, form) => {
            return (
              <FormError errors={ error } position="up">
                <NInput.File.DropArea>
                  <div style={{ display: "flex", "flexDirection": "row" }}>
                    <div className="bs-form-txt">
                      <div className="bs-form-txt-title">
                        <h1><T>issue_create</T></h1>
                      </div>
                      <div className="bs-form-txt-steps">
                        <FormStep number={1}>
                          <FormStep.Title><T>localize</T></FormStep.Title>
                          <FormStep.Comment><T>localize_write_or_map</T></FormStep.Comment>
                          <FormStep.Input>
                            <Input.Address name="address" required><T>where</T>?</Input.Address>
                          </FormStep.Input>
                        </FormStep>
                        <FormStep number={2} disabled={ this.state.disabled }>
                          <FormStep.Title><T>import_file</T></FormStep.Title>
                          <FormStep.Comment><T>issue_form_size_max</T></FormStep.Comment>
                          <FormStep.Input>
                            <Input.NewToOld.Multiple name="files" >
                              <NInput.File.Gallery limit={ 5 } isAllowedToDelete />
                            </Input.NewToOld.Multiple>
                          </FormStep.Input>
                        </FormStep>
                        <FormStep number={3} disabled={ this.state.disabled }>
                          <FormStep.Title><T>choose_category</T></FormStep.Title>
                          <FormStep.Input>
                            <Input.Select name="category" required>
                            {
                              this._categories?.map(category => (
                                <Input.Select.Value value={category._id}><T>{category.label}</T></Input.Select.Value>
                              ))
                            }
                            </Input.Select>
                          </FormStep.Input>
                        </FormStep>
                        <FormStep number={4} disabled={ this.state.disabled }>
                          <FormStep.Title><T>description</T></FormStep.Title>
                          <FormStep.Comment><T>description_max_size</T></FormStep.Comment>
                          <FormStep.Input><Input.Textarea name="description" required limit="2000" /></FormStep.Input>
                        </FormStep>
                        <div className="bs-form-txt-steps-button">
                          <Button.Text disabled={ this.state.disabled } onClick={ () => form.submit()}><T>send</T></Button.Text>
                        </div>
                      </div>
                    </div>
                    <div className="bs-form-map">
                      <Input.Map name="position" noError icon=""/>
                    </div>
                  </div>
                </NInput.File.DropArea>
              </FormError>
            )
          }}
          </Form.Model>
        </div>
        <Social />
      </ScrollBar>
    )
  }
}
export default Application.forward(["api","repository","configuration", "geolocation", "navigator", "message", "i18n"], [], IssueForm);
