import Application          from "@uLib/application";

import MicrostoreService    from "./services/microstore";
import CurrentTenantService from "./services/currentTenantFallback";

import HttpSerivce          from "@cServices/http";
import PersistentStorageService       from "@cServices/persistentStorage";
import TemporaryStorageService       from "@cServices/temporaryStorage";
import UrlSerivce           from "@cServices/url";
import MessageService       from "@cServices/message";
import GeolocationService   from "@cServices/geolocation";
import GoogleMapService     from "@cServices/googleMap";
import JwtService           from "@cServices/jwt";
import Networking           from "@cServices/networking";
import Navigator            from "@cServices/navigator";

import ApiService           from "@uServices/api";
import ConfigurationService from "@uServices/configuration";
import I18nService          from "@uServices/i18n";
import RepositoryService    from "@uServices/repository";
import SessionService       from "@services/session";
import AclService           from "@uServices/acl";
import PagerService         from "@uServices/pager";
import ReduxStoreService    from "@uServices/reduxStore";
import FileService          from "@uServices/file";
import DefaultStorageService       from "@uServices/defaultStorageService";
import UserStorageStrategyService from "@uServices/userStorageStrategy";

import UsersService         from "@uServices/business/users";
import IssueHelper          from "@uHelpers/issue";
import FileHelper           from "@uHelpers/file";
import AssistanceHelper     from "@uHelpers/assistance";
import RemoteConfiguration  from "@uServices/remoteConfiguration";
import Citizen              from "@uHelpers/citizen";
import ModelLoaders         from "@uBusiness/model";

import configuration        from "@config/value";
import * as citizenDic      from "@config/i18n";
import * as commonDic       from "@cConfig/i18n.json";
import * as universalDic    from "@uConfig/i18n";
import ReleaseService       from "@uServices/release";      
import moment               from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import "moment/locale/de";

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
dayjs.extend(relativeTime);
dayjs.extend(duration);
moment.locale('fr');

const languages = [
  { iso: "fr-FR", bs: "fr", label: "Français", shortLabel: "FR" },
  { iso: "en-GB", bs: "en", label: "English", shortLabel: "EN" },
  { iso: "nl-BE", bs: "nl", label: "Nederlands", shortLabel: "NL" },
  { iso: "de-DE", bs: "de", label: "Deutsch", shortLabel: "DE" }
];
window.document.title = "BetterStreet";

const application = new Application();
application.initializeServices([
  new DefaultStorageService(),
  new PersistentStorageService(),
  new TemporaryStorageService(),
  new Networking(),
  new HttpSerivce(),
  new JwtService(),
  new UrlSerivce(),
  new ApiService(),
  new ConfigurationService(configuration),
  new I18nService([universalDic, commonDic, citizenDic], languages, languages[0], "span"),
  new RepositoryService(ModelLoaders),
  new SessionService("citizen", "bsCitizenToken"),
  new FileService(),
  new AclService(),
  new PagerService(),
  new MessageService(),
  new ReduxStoreService(),
  new GeolocationService(),
  new GoogleMapService(),
  new UsersService(),
  new MicrostoreService(),
  new CurrentTenantService(),
  new ReleaseService("citizen", "web"),
  new Navigator(),
  new UserStorageStrategyService("userPersistentStorage", "persistentStorage"),
  new UserStorageStrategyService("userTemporaryStorage", "temporaryStorage"),
  new UserStorageStrategyService("userStorage", "storage"),
  new RemoteConfiguration(),
]);

application.addServiceAlias("simple-storage", "persistentStorage");

application.initializeHelpers([
  new IssueHelper(),
  new FileHelper(),
  new AssistanceHelper(), 
  new Citizen(),
]);

application.start();

moment.locale(application.getService("i18n").currentLanguage.bs);
moment.duration().locale(application.getService("i18n").currentLanguage.bs);
window.document.querySelector('html').lang = application.getService("i18n").currentLanguage.bs;

application.getService("i18n").onServiceUpdated.addListener({
  handleEvent: (i18nService) => {
    moment.locale(i18nService.currentLanguage.bs);
    moment.duration().locale(i18nService.currentLanguage.bs);
    window.document.querySelector('html').lang = i18nService.currentLanguage.bs;
  }
});

window.onclose = () => application.stop();
export default application;