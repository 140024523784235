import React       from "react";
import moment      from "moment";
import Input       from "@cComponents/old/input";
import Filter      from "@cComponents/filter"
import T           from "@cBehaviour/i18n";
import Button      from "@cComponents/button";
import Acl         from "@cBehaviour/acl";
import Application from "@uBehaviour/application";

import "./filter.css";

const buildCreatedAtQuery = (value) => {
  const vs = value.split(",");
  return { createdAt: { $gte: moment().subtract(parseInt(vs[0]), vs[1]).toISOString() }};
};


const getCityCode = (tenant) => tenant.informations?.postalCode.join((", "));

const IssueFilter = ({ i18n, session, noMerged}) => {
  const getQuery = React.useCallback((value) => {
    const postalCodeRegex = `^${value}`;
    const or = [
      { [i18n.queryProperty("name")]: { '$regex': `^${value}`, '$options' : 'i' }},
      {'cityCode': value },                          
    ];
    
    if(value.length > 2){
      or.push({ 'informations.postalCode': { '$regex': postalCodeRegex } })
    }
    return({ $or: or, discriminator: "town" });
  }, [i18n]);

  return(
  <div className="issues-filters">
    <div className="issues-filters-name">
      <Filter.Generic name="town" buildQuery={value => ( { "tenant": value })}>
      {(value, set, clear) => (
        <>
          <Button.Icon icon="home" shape="square" pushed={ !value } onClick={ () => clear() }></Button.Icon>
          <Input.Autocomplete.Model
            value={ value }
            noError={true}
            onChange={ component => set(component.value) }
            model={ "Tenant" }
            property={ i18n.queryProperty("name") }
            querying={ getQuery }
            labelize={(t) => `${i18n.translate(t.name)} - ${getCityCode(t)} - ${t.country.toUpperCase()}` }
          >
            {i18n.translate("all_city")}
          </Input.Autocomplete.Model>
        </>
      )}
      </Filter.Generic>
    </div>

    <Filter.Generic name="createdAt" buildQuery={ buildCreatedAtQuery }>
    {(value, set, clear) => (
      <Input.Select noError={true} value={ value } default={ null } onChange={ component => component.value ? set(component.value) : clear() } >
        <Input.Select.Value value={ null }>
          <T>issues_filter_any_time</T>
        </Input.Select.Value>
        <Input.Select.Value value={"1,hour"}>
          <T>issues_filter_less_than_an_hour</T>
        </Input.Select.Value>
        <Input.Select.Value value={"24,hour"}>
          <T>issues_filter_less_than_24_hour</T>
        </Input.Select.Value>
        <Input.Select.Value value={"1,week"}>
          <T>issues_filter_less_than_a_week</T>
        </Input.Select.Value>
        <Input.Select.Value value={"1,month"}>
          <T>issues_filter_less_than_a_month</T>
        </Input.Select.Value>
      </Input.Select>           
    )}
    </Filter.Generic> 


    <Filter.Generic name="state" buildQuery={value => ({ state: value })  }>
    {(value, set, clear) => (
      <Input.Select noError={true} value={ value } default={ null } onChange={ component => component.value ? set(component.value) : clear() } >
        <Input.Select.Value value={ null }>
          <T>issues_filter_state_all_states</T>
        </Input.Select.Value>
        <Input.Select.Value value={ "open" }>
          <T>issues_filter_state_open</T>
        </Input.Select.Value>
        <Input.Select.Value value={ "in_progress" }>
          <T>issues_filter_state_in_progress</T>
        </Input.Select.Value>
        <Input.Select.Value value={ "planned" }>
          <T>issues_filter_state_planned</T>
        </Input.Select.Value>
        <Input.Select.Value value={ "resolved" }>
          <T>issues_filter_state_resolved</T>
        </Input.Select.Value>
        <Input.Select.Value value={ "transferred" }>
          <T>issues_filter_state_transferred</T>
        </Input.Select.Value>
        <Input.Select.Value value={ "rejected" }>
          <T>issues_filter_state_rejected</T>
        </Input.Select.Value>
        {
          noMerged
            ? null
            : (
              <Input.Select.Value value={ "merged" }>
                <T>issues_filter_state_merged</T>
              </Input.Select.Value>
            )
        }
      </Input.Select>
    )}
    </Filter.Generic>

    <Acl.If resource="issues" action="create">
      <Filter.Generic name="onlyMine" buildQuery={value => ({ createdBy: session.userId })  }>
      {(value, set, clear) => (
        <Input.Boolean noError={true} value={ value } default={ false } onChange={ component => component.value ? set(component.value) : clear() }>
          <T>only_mine</T>
        </Input.Boolean>
      )}
      </Filter.Generic>
      <Filter.Generic name="followed" buildQuery={value => ({ followers: session.userId })  }>
      {(value, set, clear) => (
        <Input.Boolean noError={true} value={ value } default={ false } onChange={ component => component.value ? set(component.value) : clear() }>
          <T>followed_issues</T>
        </Input.Boolean>
      )}
      </Filter.Generic>
    </Acl.If>
  </div>
)};

export default Application.Service.forward(["i18n", "session"], IssueFilter);